import React, { useEffect, useState } from 'react';

function Application() {
    // Styling
    const customHeadingStyle = {
        fontFamily: "'Caveat Brush', sans-serif",
    };

    // Scripting
    const [surahNameInArabic, setSurahNameInArabic] = useState('');
    const [surahNameInLatin, setSurahNameInLatin] = useState('');
    const [surahMeaning, setSurahMeaning] = useState('');
    const [surahDescription, setSurahDescription] = useState('');

    const fetchQuranData = async () => {
        try {
            const randomNumber = Math.floor(Math.random() * 113) + 1;
            const response = await fetch(
                `https://quran-api.santrikoding.com/api/surah/${randomNumber}`
            );
            if (!response.ok) {
                throw new Error('Error fetching data');
            }
            const resultInData = await response.json();
            setSurahNameInArabic(resultInData.nama);
            setSurahNameInLatin(resultInData.nama_latin);
            setSurahMeaning(resultInData.arti);
            setSurahDescription(resultInData.deskripsi);
        } catch (error) {
            console.error(error.message);
            // Optionally, you can handle the error here (e.g., display an error message).
        }
    };

    useEffect(() => {
        fetchQuranData();
    }, []);

    // View
    return (
        <div>
            <div className="text-center mt-5">
                <h1 style={customHeadingStyle}>Mari Belajar Tentang Surah Didalam Al-Quran</h1>
            </div>

            <div className="searchButton my-5">
                <div className="text-center">
                    <button
                        className="btn btn-primary shadow-sm p-4 py-2"
                        onClick={() => fetchQuranData()}>
                        Berikan Saya Surah Secara Rawak
                    </button>
                </div>
            </div>

            <div className="my-5">
                <div className="row">
                    <div className="col-12 col-md-3 mb-3 mb-md-0">
                        <div className="card shadow-sm">
                            <div className="card-header bg-dark text-light">
                                <span>Nama Surah Dalam Arab</span>
                            </div>

                            <div className="card-body">
                                <h3>{surahNameInArabic}</h3> {/* Display the fetched data */}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-3 mb-3 mb-md-0">
                        <div className="card shadow-sm">
                            <div className="card-header bg-dark text-light">
                                <span>Nama Surah Dalam Latin</span>
                            </div>

                            <div className="card-body">
                                <h3>{surahNameInLatin}</h3> {/* Display the fetched data */}
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-header bg-dark text-light">
                                <span>Maksud Nama Surah</span>
                            </div>

                            <div className="card-body">
                                <h3>{surahMeaning}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card shadow-sm my-4">
                    <div className="card-header bg-dark text-light">
                        Sinopsis Surah
                    </div>

                    <div className="card-body">
                        <p className="lead" dangerouslySetInnerHTML={{ __html: surahDescription }} />
                    </div>

                </div>

                <div className="footer text-center">
                    <a href="https://sulaimanmisri.com" target='_blank' className='btn text-decoration-none'>Tentang Pembangun Website</a>
                </div>

            </div>
        </div>
    );
}

export default Application;
